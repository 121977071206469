<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8">
        <SubHeader>
          <b-row class="mt-2 mb-2">
            <b-col class="mr-auto">
              <div>Checkliste vom {{ timeStart }} bis {{ timeEnd }}</div>
            </b-col>
            <b-col cols="3" class="ml-auto">
              <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Kursen oder Software..."/>
            </b-col>
            <b-col cols="auto" class="ml-auto mb-2">
              <b-form-select v-model="courseCycleSelect.selected" :options="courseCycleSelect.options" size="sm"
                             @change="getChecklist"/>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-1">
            <b-col cols="auto">
              <b-form-checkbox v-model="changedCourses" inline>
                <b-icon-star-fill :variant="variantWarning"/> Geänderte Kurse
              </b-form-checkbox>
            </b-col>
            <b-col class="mr-auto">
              <b-form-checkbox v-model="rpcForbidden" inline>
                RPC nicht möglich
              </b-form-checkbox>
            </b-col>
            <b-col cols="auto" v-if="fg" class="mr-auto">
              <b-button variant="primary" size="sm" @click="add">Neuer Eintrag</b-button>
            </b-col>
            <b-col cols="auto">
              <b-button-group class="mb-2" size="sm">
                <b-button variant="secondary" @click="downloadChecklist">
                  Download als .{{ downloadFormat }}
                </b-button>
                <b-dropdown right size="sm">
                  <b-dropdown-item @click="downloadFormat = 'csv'">als CSV</b-dropdown-item>
                  <b-dropdown-item @click="downloadFormat = 'xlsx'">als XLSX</b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </b-col>
          </b-row>
        </SubHeader>
        <div class="sticky-placeholder-checklist"/>
        <ChecklistTable v-if="coursesFiltered" :key="tableKey" :tableItems="coursesFiltered" :loading="loading" :fg="fg"
                        @edit-software="edit"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <ScrollToTop/>
    <ChecklistModal v-if="fg" :method="method" :courseEntry="courseEntry" :courseCycleSelected="courseCycleSelect.selected"
                    @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import ChecklistModal from "@/components/lists/checkList/ChecklistModal.vue";
import ChecklistTable from "@/components/lists/checkList/ChecklistTable";

export default {
  name: "Checklist",
  components: {
    SubHeader,
    ChecklistModal,
    ChecklistTable,
    ScrollToTop,
  },
  data() {
    return {
      loading: true,
      timeStart: null,
      timeEnd: null,
      courseCycleSelect: {
        selected: null,
        options: []
      },
      tableItems: null,
      tableKey: 0,
      search: '',
      changedCourses: false,
      rpcForbidden: false,
      downloadFormat: 'csv',
      courseEntry: null,
      method: null,
      fg: this.$route.meta.fg
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    add() {
      this.method = 'add'
      this.$bvModal.show('checklistModal')
    },
    edit(data) {
      this.method = 'edit'
      this.courseEntry = data
      this.$bvModal.show('checklistModal')
    },
    refresh() {
      this.getChecklist()
    },
    async getChecklist() {
      this.loading = true
      this.tableItems = []
      await getRequest('checklist/' + this.courseCycleSelect.selected, null, this)
          .then((response) => {
            this.timeStart = response.data.time_start
            this.timeEnd = response.data.time_end
            response.data.checklist.forEach(element => {
              this.tableItems.push({
                image: element.image,
                course: element.course,
                duration: element.course_duration,
                rpc: element.rpc,
                hardware: element.hardware,
                note: element.note,
                course_data: (element.course_data) ? {
                  id: element.course_data.id,
                  displayname: element.course_data.displayname,
                  description: element.course_data.description
                } : null,
                commands: element.commands,
                id: element.id,
                change: (element.change) ? element.change : null
              })
            })
            this.loading = false;
            this.tableKey += 1;
          })
    },
    async getCourseCycles() {
      let courseCycleId = null
      await getRequest('course-cycles', null, this)
          .then((response) => {
            let currentIndex = _.findIndex(response.data, (cycle) => {
              if(cycle.current) {
                return true
              }
            })
            for(let i = currentIndex - 4; i < currentIndex + 2; i++) {
              let element = response.data[i]
              let text = element.time_start + ' - ' + element.time_end
              if(element.current) {
                courseCycleId = element.id
                text += ' aktuell'
              }
              else if(element.future) {
                text += ' forecast'
              }
              this.courseCycleSelect.options.push({
                value: element.id,
                text: text
              })
            }
          })
      this.courseCycleSelect.selected = courseCycleId
    },
    async downloadChecklist() {
      let config = {
        method: 'get',
        url: 'downloads/checklist',
        params: {
          file_type: this.downloadFormat,
          course_cycle_id: this.courseCycleSelect.selected,
        },
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    },
    coursesFiltered() {
      return _.filter(this.tableItems, (entry) => {
        if(this.changedCourses && this.rpcForbidden) {
          return (entry.course.toLowerCase().includes(this.search.toLowerCase()) ||
                  _.some(entry.commands, (command) => {
                    return command.displayname.toLowerCase().includes(this.search.toLowerCase())
                  }) || entry.note.toLowerCase().includes(this.search.toLowerCase())) && (entry.change === true && entry.rpc === 'forbidden')
        }
        else if(this.changedCourses && !this.rpcForbidden) {
            return (entry.course.toLowerCase().includes(this.search.toLowerCase()) ||
                _.some(entry.commands, (command) => {
                    return command.displayname.toLowerCase().includes(this.search.toLowerCase())
                }) || entry.note.toLowerCase().includes(this.search.toLowerCase())) && entry.change === true
        }
        else if(!this.changedCourses && this.rpcForbidden) {
            return (entry.course.toLowerCase().includes(this.search.toLowerCase()) ||
                _.some(entry.commands, (command) => {
                    return command.displayname.toLowerCase().includes(this.search.toLowerCase())
                }) || entry.note.toLowerCase().includes(this.search.toLowerCase())) && entry.rpc === 'forbidden'
        }
        else {
          return entry.course.toLowerCase().includes(this.search.toLowerCase()) ||
                _.some(entry.commands, (command) => {
                  return command.displayname.toLowerCase().includes(this.search.toLowerCase())
                }) || entry.note.toLowerCase().includes(this.search.toLowerCase())
        }
      })
    }
  },
  async mounted() {
    this.setLoadingState(true)
    await this.getCourseCycles()
    await this.getChecklist()
    this.setLoadingState(false)
  }
}
</script>

<style lang="scss" scoped>
</style>
