<template>
  <b-table :fields="table.fields" :items="tableItems" thead-class="table-header text-white text-center" :busy="loading"
           tbody-tr-class="table-row" striped small fixed caption-top @row-clicked="edit" :class="tableClasses">
    <template #head()="data">
      <div v-if="data.label === 'D'">
        <b-icon-calendar3-week id="tt-d"/>
        <b-tooltip :target="'tt-d'" triggers="hover">Dauer</b-tooltip>
      </div>
      <div v-else-if="data.label === 'R'">
        <b-icon-laptop id="tt-r"/>
        <b-tooltip :target="'tt-r'" triggers="hover">RPC</b-tooltip>
      </div>
      <div v-else>{{ data.label }}</div>
    </template>
    <template #cell(course)="data">
      <b-icon-star-fill v-if="data.item.change" class="ml-n4" :variant="variantWarning"/>
      <span :class="{ 'ml-2': data.item.change }">{{ data.value }}</span>
    </template>
    <template #cell(hardware)="data">
      <b-badge class="select" :style="{'background-color': data.value.color_background, 'color': data.value.color_font}">
        {{ data.value.displayname }}
      </b-badge>
    </template>
    <template #cell(image)="data">
      <b-badge class="select" :style="badgeStyle(data.value)">{{ data.value.displayname }}</b-badge>
    </template>
    <template #cell(rpc)="data">
      <span v-if="data.value === 'required'" :id="'tt-icon-' + data.index">
        <b-tooltip :target="'tt-icon-' + data.index" triggers="hover">
          RPC-Pflicht (Ausnahmen nur in Rücksprache mit FG_Software)
        </b-tooltip>
        <b-icon-laptop-fill :variant="variantSuccess"/>
      </span>
      <span v-else-if="data.value === 'possible'" :id="'tt-icon-' + data.index">
        <b-tooltip :target="'tt-icon-' + data.index" triggers="hover">RPC möglich</b-tooltip>
        <b-icon-laptop variant="primary"/>
      </span>
      <span v-else-if="data.value === 'remote'" :id="'tt-icon-' + data.index">
        <b-tooltip :target="'tt-icon-' + data.index" triggers="hover">
          Remotecenter-Pflicht (Ausnahmen nur in Rücksprache mit FG_Software)
        </b-tooltip>
        <b-icon-building :variant="variantSuccess"/>
      </span>
      <span v-else-if="data.value === 'forbidden'" :id="'tt-icon-' + data.index">
        <b-tooltip :target="'tt-icon-' + data.index" triggers="hover">Kein RPC möglich</b-tooltip>
        <b-icon-x-octagon-fill :variant="variantDanger"/>
      </span>
    </template>
    <template #cell(note)="data">
      <div style="white-space: pre-line">
        {{ data.item.note }}
      </div>
    </template>
    <template #cell(course_data)="data">
      <div v-if="data.item.course_data">
        <b-badge :id="'tt-course-data-' + data.item.id" class="select" variant="primary">{{ data.item.course_data.displayname }}</b-badge>
        <b-tooltip :target="'tt-course-data-' + data.item.id" triggers="hover" custom-class="tooltip-width-100">
          <div v-for="item in courseDataDescription(data.item.course_data)" :key="item" class="ml-3">
            {{ item }}
          </div>
        </b-tooltip>
      </div>
    </template>
    <template #cell(commands)="data">
      <span v-for="command in data.value" :key="command.index">
        <b-badge variant="primary" class="ml-1 select">{{ command.displayname }}</b-badge>
      </span>
    </template>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="(field, index) in table.fields" :key="index"/>
      </b-tr>
    </template>
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner variant="primary" class="align-middle"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChecklistTable",
  props: ['tableItems', 'loading', 'fg'],
  data() {
    return {
      table: {
        fields: [
          {key: 'course', label: 'Kurs', thStyle: {minWidth: '100px'}, thClass: 'th-sticky-checklist', tdClass: ['py-auto']},
          {key: 'duration', label: 'D', thStyle: {width: '30px'}, thClass: 'th-sticky-checklist', tdClass: 'text-center'},
          {key: 'rpc', label: 'R', thStyle: {width: '30px'}, thClass: 'th-sticky-checklist', tdClass: 'text-center'},
          {key: 'hardware', label: 'Hardware', thStyle: {width: '100px'}, thClass: 'th-sticky-checklist', tdClass: 'text-center'},
          {key: 'image', label: 'Image', thStyle: {width: '120px'}, thClass: 'th-sticky-checklist', tdClass: 'text-center'},
          {key: 'note', label: 'Notiz', thStyle: {width: '400px'}, thClass: 'th-sticky-checklist'},
          {key: 'course_data', label: 'Kursdaten', thStyle: {width: '120px'}, thClass: 'th-sticky-checklist', tdClass: 'text-center'},
          {key: 'commands', label: 'Pakete', thStyle: {minWidth: '100px'}, thClass: 'th-sticky-checklist'},
        ]
      },
    }
  },
  methods: {
    edit(data) {
      if(this.fg) {
        this.$emit('edit-software', data)
      }
    },
    badgeStyle(data) {
      return {
        'color': data.color_font,
        'background-color':data.color_background
      }
    },
    courseDataDescription(courseData) {
      return courseData.description.split(';')
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    },
    tableClasses() {
      if(this.userThemeId === 2) {
        return []
      }
      return ['text-white']
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/styles/Tables';
</style>
