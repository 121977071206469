<template>
  <b-modal id="checklistModal" :title="title" size="lg" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="4">
          <label>Kurs:</label>
          <b-form-input v-model="courseName" placeholder="Kursbezeichnung..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="2">
          <label>Dauer:</label>
          <b-form-input v-model="courseDuration" number type="number"/>
        </b-col>
        <b-col cols="4">
          <label>RPC:</label>
          <b-form-select v-model="rpcSelect.selected" :options="rpcSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- RPC auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="2">
          <label>Image:</label>
          <b-form-select v-model="imageSelect.selected" :options="imageSelect.options"/>
        </b-col>
        <b-col cols="4">
          <label>Kursdaten:</label>
          <b-form-select v-model="courseDataSelect.selected" :options="courseDataSelect.options"/>
        </b-col>
        <b-col cols="6">
          <label>Hardware:</label>
          <b-row>
            <b-col cols="8">
              <b-form-select v-model="hardwareConfigSelect.selected" :options="hardwareConfigSelect.options">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Hardware auswählen --</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="4" class="pl-0">
              <h5 v-if="this.hardwareConfigSelect.selected" class="mt-1">
                <b-badge :style="'background-color: ' + hardwareColor">
                  {{ hardwareName }}
                </b-badge>
              </h5>
            </b-col>
          </b-row>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Pakete:</label>
          <b-form-tags id="tags-with-dropdown" v-model="packetsSelect.value" no-outer-focus class="mb-2">
            <template v-slot="{ tags, disabled, addTag, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <draggable :list="packetsSelect.value">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag variant="primary" :title="tag" :disabled="disabled" @remove="removeTag(tag)">
                      {{ tag }}
                    </b-form-tag>
                  </li>
                </draggable>
              </ul>
              <b-dropdown size="sm" variant="outline-primary" block menu-class="w-100" dropup>
                <template #button-content>
                  <b-icon-box-seam/>
                  Pakete auswählen
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group label="Pakete suchen" label-for="tag-search-input" label-cols-md="auto" class="mb-0"
                                label-size="sm" :description="searchDesc" :disabled="disabled">
                    <b-form-input v-model="packetsSelect.search" id="tag-search-input" type="search" size="sm"
                                  autocomplete="off"/>
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider/>
                <b-dropdown-item-button v-for="option in availableOptions" :key="option.command_id"
                                        @click="onOptionClick({ option, addTag })">
                  {{ option.displayname }}
                </b-dropdown-item-button>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  Es sind keine Pakete zur Auswahl vorhanden
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notiz:</label>
          <b-form-textarea v-model="courseNote" rows="4"/>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="method === 'edit'" variant="outline-danger" class="mr-auto" @click="removeRow()">
        <b-spinner v-show="loadingRemoveButton" small/>
        Löschen
      </b-button>
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import draggable from "vuedraggable";
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "ChecklistModal",
  props: ['method', 'courseEntry', 'courseCycleSelected'],
  components: {
    draggable
  },
  data() {
    return {
      id: null,
      courseName: '',
      courseDuration: 4,
      courseNote: '',
      rpcSelect: {
        selected: null,
        options: [
          {value: 'possible', text: 'RPC möglich'},
          {value: 'forbidden', text: 'Kein RPC möglich'},
          {value: 'remote', text: 'Remotecenter-Pflicht'},
          {value: 'none', text: 'keine Software im Kurs'}
        ]
      },
      imageSelect: {
        selected: null,
        options: []
      },
      courseDataSelect: {
        selected: null,
        options: []
      },
      hardwareConfigSelect: {
        selected: null,
        options: []
      },
      packetsSelect: {
        options: [],
        value: [],
        search: ''
      },
      loadingOkButton: false,
      loadingRemoveButton: false
    }
  },
  watch: {
    method() {
      this.onShow()
    },
    courseEntry() {
      this.onShow()
    }
  },
  methods: {
    onShow() {
      if(this.method === 'edit' && this.courseEntry) {
        this.id = this.courseEntry.id
        this.courseName = this.courseEntry.course
        this.courseDuration = this.courseEntry.duration
        this.courseNote = this.courseEntry.note
        this.rpcSelect.selected = this.courseEntry.rpc
        this.imageSelect.selected = (this.courseEntry.image) ? this.courseEntry.image.id : null
        this.courseDataSelect.selected = (this.courseEntry.course_data) ? this.courseEntry.course_data.id : null
        this.hardwareConfigSelect.selected = (this.courseEntry.hardware) ? this.courseEntry.hardware.id : null
        if(this.courseEntry.commands) {
          this.packetsSelect.value = []
          this.courseEntry.commands.forEach(element => {
            this.packetsSelect.value.push(element.displayname)
          })
        }
      }
      else {
        this.reset()
      }
    },
    reset() {
      this.id = null
      this.courseName = ''
      this.courseDuration = 4
      this.courseNote = ''
      this.rpcSelect.selected = null
      this.courseDataSelect.selected =  null
      this.hardwareConfigSelect.selected = null
      this.packetsSelect.value = []
      this.packetsSelect.search = ''
      this.imageSelect.selected = _.find(this.imageSelect.options, (option) => {
        if(option.default === 1) {
          return true
        }
      }).value
    },
    onOptionClick({option, addTag}) {
      addTag(option.displayname)
      this.packetsSelect.search = ''
    },
    refresh() {
      this.$emit('refresh');
    },
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateRow();
          break;
        case 'add':
          this.addRow();
          break;
      }
    },
    async addRow() {
      this.loadingOkButton = true
      let packets = []
      this.packetsSelect.value.forEach(element => {
        packets.push(this.packetsSelect.options.filter(opt => opt.displayname === element)[0].command_id)
      })

      let data = {
        image_id: this.imageSelect.selected,
        course_cycle_id: this.courseCycleSelected,
        course: this.courseName,
        course_duration: this.courseDuration,
        rpc: this.rpcSelect.selected,
        hardware_configuration_id: this.hardwareConfigSelect.selected,
        course_data_id: this.courseDataSelect.selected,
        note: this.courseNote,
        checklist_commands: packets
      }

      await putRequest('checklist/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('checklistModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateRow() {
      this.loadingOkButton = true
      let packets = []
      this.packetsSelect.value.forEach(element => {
        packets.push(this.packetsSelect.options.filter(opt => opt.displayname === element)[0].command_id)
      })

      let data = {
        id: this.id,
        image_id: this.imageSelect.selected,
        course_cycle_id: this.courseCycleSelected,
        course: this.courseName,
        course_duration: this.courseDuration,
        rpc: this.rpcSelect.selected,
        hardware_configuration_id: this.hardwareConfigSelect.selected,
        course_data_id: this.courseDataSelect.selected,
        note: this.courseNote,
        checklist_commands: packets
      }

      await putRequest('checklist/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('checklistModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async removeRow() {
      this.loadingRemoveButton = true
      await putRequest('checklist/delete/' + this.id, null, this, 'Der Eintrag wurde erfolgreich gelöscht.', 'Der Eintrag konnte nicht gelöscht werden.')
          .then(() => {
            this.loadingRemoveButton = false
            this.refresh();
            this.$bvModal.hide('checklistModal')
          })
          .catch(() => {
            this.loadingRemoveButton = false
          })
    },
    async getPackets() {
      this.packetsSelect.options = []
      this.packetsSelect.value = []
      await getRequest('tasks/packets', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.packetsSelect.options.push(element)
            })
          })
    },
    async getHardware() {
      await getRequest('hardware/configurations', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.hardwareConfigSelect.options.push({
                value: element.id,
                text: element.displayname,
                colorFont: element.color_font,
                colorBackground: element.color_background
              })
            })
          })
    },
    async getImages() {
      this.imageSelect.options = []
      await getRequest('images', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.imageSelect.options.push({value: element.id, text: element.displayname, default: element.default})
            })
          })
    },
    async getCourseData() {
      this.courseDataSelect.options = []
      await getRequest('course-data', null, this)
          .then((response) => {
            this.courseDataSelect.options.push({
              value: null,
              text: '-',
              description: ''
            })
            response.data.forEach(element => {
              this.courseDataSelect.options.push({
                value: element.id,
                text: element.displayname,
                description: element.description
              })
            })
          })
    },
  },
  computed: {
    title() {
      if(this.method === 'edit') {
        return 'Eintrag ändern'
      }
      return 'Eintrag hinzufügen'
    },
    criteria() {
      return this.packetsSelect.search.trim().toLowerCase()
    },
    searchDesc() {
      if(this.criteria && this.availableOptions.length === 0) {
        return 'Es konnten keine Pakete nach dem Suchkriterium gefunden werden'
      }
      return ''
    },
    availableOptions() {
      const criteria = this.criteria
      let options = this.packetsSelect.options.filter(opt => this.packetsSelect.value.indexOf(opt.displayname) === -1)
      options = options.filter(opt => opt.type === 'install')
      if(criteria) {
        return options.filter(opt => opt.displayname.toLowerCase().indexOf(criteria) > -1);
      }
      return options
    },
    hardwareName() {
      let selected = this.hardwareConfigSelect.selected
      let data = _.find(this.hardwareConfigSelect.options, function(option) {
        return option.value === selected
      })
      if(data) {
        return data.text
      } else {
        return null
      }
    },
    hardwareColor() {
      let selected = this.hardwareConfigSelect.selected
      let data = _.find(this.hardwareConfigSelect.options, function(option) {
        return option.value === selected
      })
      if(data) {
        return data.colorBackground
      } else {
        return null
      }
    },
    disabledButton() {
      return !(this.hardwareConfigSelect.selected && this.rpcSelect.selected && this.courseName);
    },
      ...mapGetters(['userThemeId']),
      variantSuccess() {
        if(this.userThemeId === 3) { return 'success-deuteranopia' }
        return 'success'
      },
      variantDanger() {
        if(this.userThemeId === 3) { return 'danger-deuteranopia' }
        return 'danger'
    }
  },
  mounted() {
    this.getImages()
    this.getPackets()
    this.getHardware()
    this.getCourseData()
  }
}
</script>

<style lang="scss">
.dropdown-menu {
  max-height: 30rem;
  overflow-y: auto;
}
</style>
